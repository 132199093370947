<!-- eslint-disable vue/no-v-html -->
<template>
    
    <div class="v-pay">
        <img src="@/assets/images/01.png" alt="" class="leftImg">
        <div class="centerBox">
            <span>{{propValue.name}}</span>
            <span style="margin-top:4px">现在只需要{{propValue.price}}元</span>
        </div>
        <div class="rightBtn" @click="handlerClick('v-pay')">{{ propValue.btnTitle }}</div>
    </div>
        
</template>

<script>
export default {
    props: {
        propValue: {
            type: Object,
            require: true,
            default: () => {},
        },
        handlerClick: {
            type: Function,
        }
    },
    data() {
        return {
            
        }
    },
    computed: {
    },
    methods: {
        
    },
}
</script>

<style lang="scss" scoped>
.v-pay {
    width: 100%;
    height: 48px;
    // border: 1px solid #1e6bff;
    display: flex;
    .leftImg {
        display: block;
        width: 28%;
        height: 40px;
        margin-top: 4px;
        margin-left: 8px;
    }
    .centerBox {
        display: flex;
        flex-direction: column;
        margin-top: 5px;
        margin-left: 10px;
        font-size: 12px;
        color: #cccccc;
        flex: 1;
    }
    .rightBtn {
        width: 32%;
        height: 35px;
        background: #f3033b;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        color: #ffffff;
        line-height: 35px;
        margin-right: 5px;
        margin-top: 6px;
    }
}
</style>
