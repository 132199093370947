<!-- eslint-disable vue/no-v-html -->
<template>
    <div class="v-order">
        <span class="boxTitle">订单信息</span>
        <div class="box">
            <img :src="propValue.cover?propValue.cover:img" alt="" class="left">
            <div class="right">
                <span style="font-size:12px;color:#333333;margin-top:2px">{{ propValue.title }}</span>
                <span style="font-size:10px;color:#999999;margin-top:2px">{{ propValue.desc }}</span>
                <span style="font-size:10px;color:#ff494a;margin-top:2px">¥{{ propValue.price }}</span>
            </div>
        </div>
        <div class="payBox">
            <el-form-item
                class="item" 
                >
                
                <!-- <div class="top">
                    <img src="@/assets/images/wx.png" alt="" class="payImg">
                    <span style="width:50px;flex:1;margin-left:12px;font-size:12px;color: #333333;">微信支付</span>
                    <span style="margin-right:22px"><el-radio v-model="payType" size="medium" :checked="true" label="WX">{{empty}}</el-radio></span>
                </div> -->
                <div class="bottom" style="margin-top:16px">
                    <img src="@/assets/images/zfb.png" alt="" class="payImg">
                    <span style="width:50px;flex:1;margin-left:12px;font-size:12px;color: #333333;">支付宝支付</span>
                    <span style="margin-right:22px"><el-radio  v-model="payType" size="medium" label="ALI">{{empty}}</el-radio></span>
                </div>
                <div class="bottom" style="margin-top:16px">
                    <img src="@/assets/svg/wxcode.svg" alt="" class="payImg">
                    <span style="width:50px;flex:1;margin-left:12px;font-size:12px;color: #333333;">微信支付</span>
                    <span style="margin-right:22px"><el-radio  v-model="payType" size="medium" label="WX">{{empty}}</el-radio></span>
                </div>
            </el-form-item>
        </div>
    </div>
</template>

<script>
import img from "@/assets/images/01.png";
export default {
  name: "VpayAll",
    data() {
        return {
            wxPay: true,
            zfbPay: false,
            empty: '',
            payType: 'ALI',
            img
        }
    },
    props: {
        propValue: {
            type: Object,
            require: true,
            default: () => {},
        },
        element: {
            type: Object,
            default: () => {},
        },
        ruleForm: {
            type: Object,
            default: () => {},
        },
    },
    watch: {
        payType() {
            this.$store.commit('setPayType', this.payType)
        }
    },
    methods: {
        
    },
}
</script>

<style lang="scss" scoped>
.payBox >>> .el-checkbox__original{
    opacity: 1!important;
}
.v-order {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .boxTitle {
        display: inline-block;
        margin-top: 8px;
        margin-left: 16px;
        font-size: 12px;
        font-weight: 400;
        color: #333333;
    }
    .box {
        display: flex;
        margin-top: 8px;
        margin-left: 16px;
        .left {
            display: block;
            width: 28%;
            margin-top: 2px;
        }
        .right {
            display: flex;
            flex-direction: column;
            margin-left: 8px;
        }
    }
    .payBox {
        display: flex;
        flex-direction: column;
        margin-top: 25px;
        .top,.bottom {
            display:flex;
            align-items: center;
            margin-left:16px;
            .payImg {
                display: inline-block;
                width: 18px;
                height: 18px;
                // margin-left: 16px;
            }
        }
    }
}
</style>
