<template>
    <div class="imageConpon">
        <div class="backimg"  @click="toGo()">
            <a v-if="propValue.href" :href="propValue.href" target="_banlk">
                <img :src="propValue.backImg" alt="" :style="{
                     width: propValue.widthImg+'%',
            height: propValue.heightImg+'%',
            borderRadius:propValue.borderR+'%',
            transform: 'rotateZ(' + propValue.tranc +'deg)',
                }" />
            </a>
            <img v-else :src="propValue.backImg" alt="" :style="{
                 width: propValue.widthImg+'%',
            height: propValue.heightImg+'%',
            borderRadius:propValue.borderR+'%',
            transform: 'rotateZ(' + propValue.tranc +'deg)',
            }" />
        </div>
        
    </div>
</template>
<script>
import { mapState } from "vuex";
export default {
    props: {
        propValue: {
            type: Object,
            require: true,
            default: () => {},
        },
    },
    element: {
        type: Object,
        default: () => {},
    },
    data() {
        return {
            // srcImgUrl:'',
        }
    },
    computed: mapState([
        "componentData",
    ]),
    created () {
        
    },
    methods: {
        toGo(){
            
        }
    },
}
</script>

<style scoped lang="scss">
.backimg {
  display: flex;
  justify-content: center;
}
.backimg>a{
  display: flex;
  justify-content: center;
}
</style>
