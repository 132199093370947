//axios的封装
//1.引入 axios
import axios from "axios";
import router from "../../router/index"
import { Message } from "element-ui";
//2.创建axios 实例
const request = axios.create({
  //3.配置
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 10 * 60 * 1000 //1分钟
});

//4. 请求拦截
request.interceptors.request.use(
  config => {
    //配置 请求拦截中的token
    console.log('token')
    let token = sessionStorage.getItem("galaxyToken") || "";
    if (token || token !== "") {
      config.headers.token = token
      config.headers['Content-Type'] = 'application/json'
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

//5.响应拦截
request.interceptors.response.use(
  response => {
    const res = response.data;
    if (res.code != "SUCCESS" && res.code !== "BIZ_ERROR") {
      Message({
        message: res.message || "系统错误",
        type: "error",
        duration: 2000
      });
      return Promise.reject(res);
    } else if (res.code === "BIZ_ERROR" && res.message === "未登录") {
      sessionStorage.removeItem("galaxyToken");
      return Promise.reject(res);
    } else if (res.code === "BIZ_ERROR" && (res.message.indexOf('未启用') !== -1 || res.message.indexOf('落地页不存在') !== -1)){
      Message({
        message: res.message,
        type: "error",
        duration: 2000
      });
      router.push({
        path: 'error-page'
      })
      return Promise.reject(res);
    }
    return res;
  },
  error => {
    console.error(error);
    return Promise.reject(error);
  }
);

export default request;
