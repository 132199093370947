var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"imageConpon"},[_c('div',{staticClass:"backimg",on:{"click":function($event){return _vm.toGo()}}},[(_vm.propValue.href)?_c('a',{attrs:{"href":_vm.propValue.href,"target":"_banlk"}},[_c('img',{style:({
                 width: _vm.propValue.widthImg+'%',
        height: _vm.propValue.heightImg+'%',
        borderRadius:_vm.propValue.borderR+'%',
        transform: 'rotateZ(' + _vm.propValue.tranc +'deg)',
            }),attrs:{"src":_vm.propValue.backImg,"alt":""}})]):_c('img',{style:({
             width: _vm.propValue.widthImg+'%',
        height: _vm.propValue.heightImg+'%',
        borderRadius:_vm.propValue.borderR+'%',
        transform: 'rotateZ(' + _vm.propValue.tranc +'deg)',
        }),attrs:{"src":_vm.propValue.backImg,"alt":""}})])])}
var staticRenderFns = []

export { render, staticRenderFns }