export default {
    state: {
        flowPageAllData: {},// 落地页所有详细数据
        flowPageComponentData: [], // 内容页组件数据
        messagePageComponentData: [], // 信息页组件数据
        tipPageComponentData: [], // 提交之后页组件数据
        formAllAnswer: {}, // 表单所有提交数据
        perview: false,
    },
    mutations: {
        // 设置组件数据
        setComponentData(state, testingData = []) {
            if ( testingData.length > 0 ) {
                state.flowPageComponentData = [];
                state.messagePageComponentData = [];
                state.tipPageComponentData = [];
                testingData.map(item => {
                    let compItem = JSON.parse(item.record);
                    compItem.id = item.id;//表单ID，提交数据时需要
                    compItem.flowPageFormItemVOList = item.flowPageFormItemVOList;//表单ID，提交数据时需要
                    if (item.type == 1) {
                        state.flowPageComponentData.push(compItem)
                    } else  if (item.type == 2) {
                        state.messagePageComponentData.push(compItem)
                    } else  if (item.type == 3) {
                        state.tipPageComponentData.push(compItem)
                    }
                })
            }
        },
        // 设置落地页详细数据
        setFlowPageAllData(state, flowData = {}) {
            state.flowPageAllData = flowData;
        },
        // 设置落地页预览模式
        setPerview(state, perview) {
            state.perview = perview;
        },
        // 设置表单所有提交数据
        setFormAllAnswer(state, formAllAnswer = {}) {
            state.formAllAnswer = formAllAnswer;
        },
    },
    actions: {
       
    }
}