<template>
  <div class="gender-radio">
      <el-form-item :label="propValue.title" 
      :prop="'formItem_' + element.id"
       :class="[propValue.arrangementMode == 1? 'radio-y':'radio-x']">
      <!-- 用户操作 -->
        <el-radio-group class="options" 
        v-if="element.flowPageFormItemVOList" 
        v-model="ruleForm['formItem_' + element.id]" >
          <el-radio v-for="(item,index) in element.flowPageFormItemVOList" 
          :key="index" :label="item.id" class="radio-item">{{item.content}}</el-radio>

        </el-radio-group>
        <!-- 预览时 -->
        <el-radio-group class="options" 
        v-else-if="propValue.option" 
        v-model="ruleForm['formItem_' + element.id]" >
          <el-radio v-for="(item,index) in propValue.option" 
          :key="index" :label="item.label" class="radio-item">{{item.title}}</el-radio>

        </el-radio-group>
      </el-form-item>
  </div>
</template>

<script>
export default {
  props: {
    propValue: {
      type: Object,
      default: () => {},
    },
    element: {
      type: Object,
      default: () => {},
    },
    handleChange: {
      type: Function,
    },
    ruleForm: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      
    }
  },
  mounted() {
    this.$set(this.propValue, 'arrangementMode', this.propValue.arrangementMode)
    console.log(this.element, 'element')
  },
  methods: {
    
  }
}
</script>

<style lang="scss" scoped>
.gender-radio {
  width: 100%;
  padding: 0 12px;
  box-sizing: border-box;
  .radio-y {
    display: flex;
    align-items: center;
    ::v-deep.el-form-item__content {
        width: calc(100% - 60px);
    }
    ::v-deep.el-radio__label {
        width: initial;
      }
  }
  .radio-x {
    display: flex;
    flex-direction: column;
    
  }
  ::v-deep.el-form-item__label {
      text-align: left !important;
      font-size: 12px;
    }
  ::v-deep.el-radio-group{
    display: block;
  }
  .el-form-item {
    margin: 0px;
    
  }
  .options {
    ::v-deep.el-radio {
      display: inline-flex;
      align-items: center;
      padding: 7px 0;
      width: 50%;
      box-sizing: border-box;
      margin: 0;
      .el-radio__label {
        display: inline-block;
        overflow: hidden;
        white-space: pre-wrap;
        font-size: 12px;
      }
    }
  }
}
  .radio-item{
    margin: 10px;
  }

</style>
