<template>
  <div class="v-text">
    <el-form-item :label="propValue.title" class="item" :prop="'formItem_phone_' + element.id">
      <el-input
        ref="input"
        type="number"
        v-model="ruleForm['formItem_phone_' + element.id]"
        @input="changePhone"
        @blur="propValue.interaction = false"
        placeholder="请输入手机号"
      />
    </el-form-item>
    <!-- propValue.isSend是否可以发送验证码 -->
    <el-form-item
      class="item-code"
      :prop="'formItem_code_' + element.id"
      v-if="propValue.isSend"
      v-show="!propValue.interaction"
    >
      <!-- interaction为false，常规：显示验证码，为true，输入后显示验证码 -->
      <div class="input_all">
        <el-input
          ref="input"
          type="number"
          v-model="ruleForm['formItem_code_' + element.id]"
          placeholder="请输入验证码"
        />
        <el-button
          :ref="element.mark"
          class="btn"
          type="primary"
          @click.stop="sendCode"
        >{{ codeBtnText }}</el-button>
      </div>
    </el-form-item>
  </div>
</template>

<script>
import { sendVerificationCode, submitMobile } from '../../api/index'
import { testPhone } from '../../utils/index'
import md5 from 'js-md5'
export default {
  props: {
    propValue: {
      type: Object,
      require: true,
      default: () => { },
    },
    element: {
      type: Object,
      default: () => { },
    },
    ruleForm: {
      type: Object,
      default: () => { },
    },
    handlerClick: {
      type: Function,
      default: () => { },
    },
  },
  data() {
    return {
      canEdit: false,
      ctrlKey: 17,
      isCtrlDown: false,
      codeBtnText: '发送验证码',
      isSendCode: false,//是否发送验证码
    };
  },
  methods: {
    // 发送验证
    sendCode() {
      if (this.codeBtnText !== '发送验证码' || this.$store.state.flowPage.perview) return;//正在倒计时，不可再次请求。
      let phone = this.ruleForm['formItem_phone_' + this.element.id];
      sendVerificationCode(phone).then(res => {
        if (res.code === 'SUCCESS') {
          this.isSendCode = true;
          this.codeOutTime();
        } else {
          this.$message.error("获取验证码失败，请稍后重试！")
        }
      })
    },
    // 验证码倒计时
    codeOutTime() {
      let s = 60;
      this.codeBtnText = s + 'S';
      let timer = setInterval(() => {
        s -= 1
        this.codeBtnText = s + 'S';
        if (s === 0) {
          clearInterval(timer);
          timer = null;
          this.codeBtnText = '发送验证码';
        }
      }, 1000);
    },
    // 提交用户数据
    phoneSubmitUserData() {
      if (JSON.stringify(this.$store.state.userDatabaseForm) === '{}') {//用户没有提交过数据，触发数据保存操作
        this.handlerClick('v-PhoneText');
      }
    },
    // 校验手机验证码
    submitMobile() {
      console.log('submitMobile')
      if (this.$store.state.flowPage.perview) return;
      // if (this.isSendCode === true) {
      let date = new Date().valueOf(),
        flowPageId = this.$store.state.flowPage.flowPageAllData.id,
        mobile = this.ruleForm['formItem_phone_' + this.element.id];
      console.log('' + flowPageId + mobile + date, 'flowPageId + mobile + date')
      submitMobile({
        date: date,
        flowPageId: flowPageId,
        mobile: mobile,
        verificationCode: this.ruleForm['formItem_code_' + this.element.id],
        sign: md5(flowPageId + mobile + date),
      }).then(res => {
        if (res.code === 'SUCCESS') {
          this.$store.commit('setMobileData', res.data);
          // this.phoneSubmitUserData();
          // this.ruleForm['formItem_code_' + this.element.id] == ''
        } else {
          this.ruleForm['formItem_code_' + this.element.id] == ''
          this.$message.error(res.message)
        }
      });
      // }
    },
    // 手机验证码长度监听，显示验证码
    changePhone(phone) {
      if (testPhone(phone)) {
        this.propValue.interaction = false;
      }
    }
  },
};
</script>
<style lang="css" scoped>
.input_all >>> .el-button {
  padding: 0 !important;
}
</style>
<style lang="scss" scoped>
.input_all {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  .btn {
    height: 40px;
    margin-left: 6px;
    width: 126px;
    overflow: hidden;
  }
}
.item,
.el-form-item {
  margin-bottom: 0px !important;
}
>>> .el-input__inner {
  background-color: #f4f7fe !important;
  border-color: #f4f7fe !important;
}
>>> .el-textarea__inner {
  background-color: #f4f7fe !important;
  border-color: #f4f7fe !important;
}

.red {
  color: red;
}
.v-text {
  font-size: 12px;
  color: #333333;
  padding: 10px;
}
.divcell {
  display: table-cell;
  width: 100%;
  height: 100%;
  outline: none;
}

.preview {
  user-select: none;
}
</style>
