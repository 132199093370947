<template>
  <div
    class="v-text"
  >
      <el-form-item :label="propValue.title" 
      class="item" 
      :prop="'formItem_' + element.id">
        <el-input ref="input" 
        v-model="ruleForm['formItem_' + element.id]" 
        :class="propValue.orderType?'':'divcell'" 
        :placeholder="propValue.itemName" />
      </el-form-item>
  </div>
</template>

<script>

export default {
  props: {
    propValue: {
      type: Object,
      require: true,
      default: () => {},
    },
    element: {
      type: Object,
      default: () => {},
    },
    ruleForm: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      canEdit: false,
      ctrlKey: 17,
      isCtrlDown: false,
    };
  },
  methods: {
    handleInput(e) {
      this.$emit("input", this.element, e.target.innerHTML);
    },

    handleKeydown(e) {
      if (e.keyCode == this.ctrlKey) {
        this.isCtrlDown = true;
      // } else if (
      //   this.isCtrlDown &&
      //   this.canEdit &&
      //   keycodes.includes(e.keyCode)
      // ) {
        } else if (
        this.isCtrlDown &&
        this.canEdit
      ) {
        e.stopPropagation();
      } else if (e.keyCode == 46) {
        // deleteKey
        e.stopPropagation();
      }
    },

    handleKeyup(e) {
      if (e.keyCode == this.ctrlKey) {
        this.isCtrlDown = false;
      }
    },

    handleMousedown(e) {
      if (this.canEdit) {
        e.stopPropagation();
      }
    },

    clearStyle(e) {
      e.preventDefault();
      const clp = e.clipboardData;
      const text = clp.getData("text/plain") || "";
      if (text !== "") {
        document.execCommand("insertText", false, text);
      }

      this.$emit("input", this.element, e.target.innerHTML);
    },

    handleBlur(e) {
      this.element.propValue = e.target.innerHTML || "&nbsp;";
      const html = e.target.innerHTML;
      if (html !== "") {
        this.element.propValue = e.target.innerHTML;
      } else {
        this.element.propValue = "";
        this.$nextTick(() => {
          this.element.propValue = "&nbsp;";
        });
      }
      this.canEdit = false;
    },

    setEdit() {
      if (this.element.isLock) return;

      this.canEdit = true;
      // 全选
      this.selectText(this.$refs.text);
    },

    selectText(element) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(element);
      selection.removeAllRanges();
      selection.addRange(range);
    },
  },
};
</script>

<style lang="scss" scoped>
.item,.el-form-item{
margin-bottom: 0px !important;
}
>>> .el-input__inner {
  background-color: #f4f7fe !important;
  border-color: #f4f7fe !important;

}
.red {
  color: red;
}
.v-text {
  font-size: 12px;
  color: #333333;
  padding: 10px;
  // // border-top: 1px solid #1e6bff;
  // // border-bottom: 1px solid #1e6bff;
  // line-height: 26px;

}
  .divcell {
    display: table-cell;
    width: 100%;
    height: 100%;
    outline: none;
  }

.preview {
  user-select: none;
}
</style>
