<!-- eslint-disable vue/no-v-html -->
<template>
    <div class="v-text" >
        <div class="textAll" 
        :class="propValue.layout === 'right'?'text-right': propValue.layout === 'center'?'text-center': 'text-left'" 
        :style="{fontSize:propValue.size+'px',color:propValue.color}">{{ propValue.content }}</div>
    </div>
</template>

<script>

export default {
    props: {
        propValue: {
            type: Object,
            require: true,
            default: () => {},
        },
    },
    data() {
        return {
            
        }
    },
    computed: {

    },
    methods: {
    },
}
</script>

<style lang="scss" scoped>
.v-text {
    width: 100%;
    display: inline-block;
    font-size: 12px;
    color: #333333;
    line-height: 26px;
  .textAll {
    padding: 5px;
    margin: 0 auto;
    box-sizing: border-box;
  }

    .canEdit {
        cursor: text;
        height: 100%;
    }
    .text-left{
      text-align: justify;
    }
    .text-center{
      text-align: center;
    }
    .text-right{
      text-align: right;
    }
}

.preview {
    user-select: none;
}
</style>
