<template>
  <div class="v-text">
      <el-form-item :label="propValue.title" 
      class="item" 
      :prop="'formItem_' + element.id">
        <el-input 
        ref="input" 
        :type="propValue.changerow=='多行'?'textarea':'text'"
        v-model="ruleForm['formItem_' + element.id]" 
        placeholder="请输入" />
      </el-form-item>
  </div>
</template>

<script>
import { mapState } from "vuex";
// import { keycodes } from "@/utils/shortcutKey.js";

export default {
  props: {
    propValue: {
      type: Object,
      require: true,
      default: () => {},
    },
    element: {
      type: Object,
      default: () => {},
    },
    ruleForm: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      canEdit: false,
      ctrlKey: 17,
      isCtrlDown: false,
    };
  },
  computed: {
    ...mapState(["editMode"]),
  },
  methods: {
    handleInput(e) {
      this.$emit("input", this.element, e.target.innerHTML);
    },
    handleMousedown(e) {
      if (this.canEdit) {
        e.stopPropagation();
      }
    },

    clearStyle(e) {
      e.preventDefault();
      const clp = e.clipboardData;
      const text = clp.getData("text/plain") || "";
      if (text !== "") {
        document.execCommand("insertText", false, text);
      }

      this.$emit("input", this.element, e.target.innerHTML);
    },

    handleBlur(e) {
      this.element.propValue = e.target.innerHTML || "&nbsp;";
      const html = e.target.innerHTML;
      if (html !== "") {
        this.element.propValue = e.target.innerHTML;
      } else {
        this.element.propValue = "";
        this.$nextTick(() => {
          this.element.propValue = "&nbsp;";
        });
      }
      this.canEdit = false;
    },

    setEdit() {
      if (this.element.isLock) return;

      this.canEdit = true;
      // 全选
      this.selectText(this.$refs.text);
    },

    selectText(element) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(element);
      selection.removeAllRanges();
      selection.addRange(range);
    },
  },
};
</script>

<style>

.v-text /deep/ .el-input__inner {
  background-color: #f4f7fe !important;
  border-color: #f4f7fe !important;

}
.v-text /deep/ .el-textarea__inner {
  background-color: #f4f7fe !important;
  border-color: #f4f7fe !important;

}
</style>

<style lang="scss" scoped>
.item,.el-form-item{
margin-bottom: 0px !important;
}
.red {
  color: red;
}
.v-text {
  font-size: 12px;
  color: #333333;
  padding: 10px;
  // // border-top: 1px solid #1e6bff;
  // // border-bottom: 1px solid #1e6bff;
  // line-height: 26px;

}
  .divcell {
    display: table-cell;
    width: 100%;
    height: 100%;
    outline: none;
  }

.preview {
  user-select: none;
}
</style>
