<template>
    
    <div class="v-images"> 
        <img src="../assets/images/add.png" alt="" 
            class="panelImg">
    </div>
</template>

<script>
import imgList from '../custom-component/ImagesLists'
export default {
    props: {
        propValue: {
            type: String,
            require: true,
            default: '',
        },
    },
    element: {
        type: Object,
        default: () => {},
    },
    data() {
        return {
            imgList
        }
    },
    computed: {
    },
    methods: {
        handleDragStart(e) {
            e.dataTransfer.setData('index', e.target.dataset.index)
        },
    },
}
</script>

<style scoped lang="scss">
.imagesBox {
    display: flex;
    margin-left: 16px;
    margin-top: 16px;
    .titleLeft {
        font-size: 14px;
        font-weight: 500;
        color: #cacaca;
    }
    .titleRight {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        margin-top: 3px;
    }
}
.panelBox {
    width: 201px;
    height: 115px;
    background: #232323;
    border: 1px dashed #1e6bff;
    border-radius: 4px;
    margin: 0 auto;
    margin-top: 13px;
    display: flex;
    flex-direction: column;
    .panelImg {
        display: inline-block;
        width: 25px;
        height: 27px;
        margin: 0 auto;
        margin-top: 32px;
    }
    .panelTitle {
        font-size: 12px;
        font-weight: 400;
        color: #5b93fe;
        text-align: center;
        margin-top: 13px;
    }
}
</style>