<template>
    <div class="rect-shape">
        <v-text 
        :prop-value="element.propValue" 
        :element="element" />
    </div>
</template>

<script>
import VText from './VText.vue';
export default {
    components: {
        VText,
    },
    props: {
        propValue: {
            type: String,
            require: true,
            default: '',
        },
        element: {
            type: Object,
            default: () => {},
        },
    },
}
</script>

<style lang="scss" scoped>
.rect-shape {
    width: 100%;
    height: 100%;
    overflow: auto;
}
</style>
