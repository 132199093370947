<template>
  <div class="v-button v-button-flow" style="padding: 20px 0">
    <el-button :ref="element.mark" 
    type="primary"
    @click="handlerClick('v-button', propValue)"
     :style="{width: element.style.width + '%'}">{{propValue.title}}</el-button>
  </div>
</template>

<script>
export default {
  props: {
    propValue: {
      type: Object,
    },
    element: {
      type: Object,
    },
    handlerClick: {
      type: Function,
    }
  },
  computed: {
    styleData() {
      return this.element.style
    },
  },
  watch: {
    styleData: {
      handler: function (val) {
        console.log(val)
        //可以做些相应的处理
        let mark = this.element.mark
        this.$refs[mark].$el.style.backgroundColor = val.buttonColor
        this.$refs[mark].$el.style.borderColor = val.frameColor
        this.$refs[mark].$el.style.borderWidth = val.frameSize + "px"
        // this.$refs[mark].$el.style.width = val.width + "px"
        this.$refs[mark].$el.style.height = val.height + "px"
        this.$refs[mark].$el.style.borderRadius = val.fillet + "px"
      },
      deep: true
    }
  },
  mounted() {
    // console.log(this.backgroundColor, 'backgroundColor')
    // let mark = this.element.mark
    // this.$refs[mark].$el.style.backgroundColor = this.element.style.backgroundColor
    // console.log(this.$refs[mark].$el.style.borderWidth, 'this.$refs.mark')
    // console.log(this.element.style.backgroundColor, 'style')
  }
}
</script>

<style lang="scss" scoped>
.v-button {
  //   position: absolute;
  //   bottom: 0px;
  //   right: 0px;
  width: 100%;
  text-align: center;
  .el-button {
    width: 224px;
  }
}
.v-button-flow{
  background-color: #fff;
}
</style>
