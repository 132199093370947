import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'flow-page',
    component: () => import('../views/flow-page/flow-page.vue'),
  },
  {
    path: '/message-page',
    name: 'message-page',
    component: () => import('../views/message-page/message-page.vue'),
  },
  {
    path: '/tip-page',
    name: 'tip-page',
    component: () => import('../views/tip-page/tip-page.vue'),
  },
  {
    path: '/pay-page',
    name: 'pay-page',
    component: () => import('../views/pay-page/pay-page.vue'),
  },
  {
    path: '/privacy-page',
    name: 'privacy-page',
    component: () => import('../views/privacy-page/privacy-page.vue'),
  },
  {

    path: '/error-page',
    name: 'error-page',
    component: () => import('../views/error-page/error-page.vue'),
  },
  {
    path: '/addTeacherCode',
    name: 'addTeacherCode',
    component: () => import('../views/addTeacherCode/index.vue'),
  },
  {
    path: '/page-404',
    name: 'page-404',
    component: () => import('../views/page-404/page-404.vue'),
  },
]
// 修改路由模式为history
const router = new VueRouter({
  mode: 'history',//mode为history
  routes,
})

router.beforeEach(async (to, form, next) => {
  document.title = store.state.flowPage.flowPageAllData.title?store.state.flowPage.flowPageAllData.title: ' ';//设置页面顶部标题
  if (store.state.id && (to.name === 'message-page' || to.name === 'pay-page' || to.name === 'privacy-page')) {

    next();
  }else if(to.fullPath.indexOf('id') !== -1) {
    next();
  } else if(routes.some(item => item.name == to.name)) {
    next();
  }else{
    next({
      path: '/page-404'
    });
  }
  
})
  
export default router