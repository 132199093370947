<template>
  <div class="v-buttonPay" style="margin-top: 20px;margin-bottom: 20px">
    <div class="payButton" :style="{
        width:propValue.Bwidth+'%',
        height:propValue.Bheight+'px',
        backgroundColor:propValue.backGroundB,
        borderRadius:propValue.borderRadiusB+'px',
        color:propValue.textColor,
        lineHeight:propValue.Bheight+'px',
        fontSize:propValue.size+'px',
        }"
        @click="handlerClick('v-buttonPay')">{{propValue.title}}</div>
  </div>
</template>

<script>
  export default {
    props: {
      propValue: {
        type: Object,
      },
      element: {
        type: Object,
      },
      handlerClick: {
        type: Function,
      }
    },
    computed: {

    },
  }
</script>

<style lang="scss" scoped>
.payButton {
  margin: 0 auto;
  text-align: center;
}
</style>
