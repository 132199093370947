<!-- eslint-disable vue/no-v-html -->
<template>
    <div class="v-privacy">
        <el-form-item
           
            class="item"
            :prop="'formItem_' + element.id"
        >
            <el-checkbox
                v-model="ruleForm['formItem_' + element.id]"
                true-label="1"
                false-label="0"
                class="flex-center-center"
            >
                <span :style="{ fontSize: propValue.textSize + 'px' }">同意</span>
                <span :style="{ fontSize: propValue.textSize + 'px', color: propValue.textColor, }" @click="toPrivacy">{{ propValue.title }}</span>
                <!-- <a :href="propValue.href" target="_blank">
                    <span
                        :style="{ fontSize: propValue.textSize + 'px', color: propValue.textColor, }"
                    >{{ propValue.title }}</span>
                </a> -->
            </el-checkbox>
        </el-form-item>
    </div>
</template>

<script>
import event from '../evnts.js'
export default {
    props: {
        propValue: {
            type: Object,
            require: true,
            default: () => { },
        },
        element: {
            type: Object,
            require: true,
            default: () => { },
        },
        ruleForm: {
            type: Object,
            default: () => { },
        },
    },
    data() {
        return {

        }
    },
    mounted() {
        event.$on('lookPrivacy', () => {
            this.ruleForm['formItem_' + this.element.id] = "1";
        })
    },
    methods: {
        toPrivacy() {
            this.$router.push({
                path: '/privacy-page',
            })
        }
    },
}
</script>

<style scoped>
>>>.el-form-item{
    margin-bottom: 0;
}

</style>

<style lang="scss" scoped>
.v-privacy {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30px;
}
.flex-center-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
