var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-buttonPay",staticStyle:{"margin-top":"20px","margin-bottom":"20px"}},[_c('div',{staticClass:"payButton",style:({
      width:_vm.propValue.Bwidth+'%',
      height:_vm.propValue.Bheight+'px',
      backgroundColor:_vm.propValue.backGroundB,
      borderRadius:_vm.propValue.borderRadiusB+'px',
      color:_vm.propValue.textColor,
      lineHeight:_vm.propValue.Bheight+'px',
      fontSize:_vm.propValue.size+'px',
      }),on:{"click":function($event){return _vm.handlerClick('v-buttonPay')}}},[_vm._v(_vm._s(_vm.propValue.title))])])}
var staticRenderFns = []

export { render, staticRenderFns }