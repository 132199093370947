import store from '../store/index'
//getQueryVariable 获取路径参数
export function getQueryVariable(variable) {
    var query = window.location.href.substring(window.location.href.indexOf('?') + 1);
    console.log(query)
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) { return pair[1]; }
    }
    return "";
}

// updateVueX vuex数据缓存，防止页面刷新丢失数据
export const updateVueX = () => {
    //在页面加载时读取sessionStorage里的状态信息
    let storage = sessionStorage.getItem("store");
    if (storage) {
        store.replaceState(Object.assign({}, store.state, JSON.parse(storage)))
    }
    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
        sessionStorage.setItem("store", JSON.stringify(store.state))
    })
}
    // getUserIP 获取用户IP地址
export const getUserIP = (onNewIP) => {
  let MyPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;
  let pc = new MyPeerConnection({
      iceServers: []
  });
  let noop = () => {};
  let localIPs = {};
  let ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g;
  let iterateIP = (ip) => {
      if (!localIPs[ip]) onNewIP(ip);
      localIPs[ip] = true;
  };
  pc.createDataChannel('');
  pc.createOffer().then((sdp) => {
      sdp.sdp.split('\n').forEach(function(line) {
          if (line.indexOf('candidate') < 0) return;
          line.match(ipRegex).forEach(iterateIP);
      });
      pc.setLocalDescription(sdp, noop, noop);
  }).catch((reason) => {
      console.log(reason)
  });
  pc.onicecandidate = (ice) => {
      if (!ice || !ice.candidate || !ice.candidate.candidate || !ice.candidate.candidate.match(ipRegex)) return;
      ice.candidate.candidate.match(ipRegex).forEach(iterateIP);
  };
}
    // testPhone 手机号验证
export const testPhone = (phone) => {
    return /^1[3456789]\d{9}$/.test(phone)
};