<template>
  <div id="app">
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
document.title = '落地页'
export default {
  name: 'App',
}
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  width: 100vw;
  overflow-x: hidden;
  height: 100%;
}
#app {
  position: relative;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
}

.down-btn {
  position: absolute;
  bottom: 0;
  z-index: 9;
  width: 100vw;
}
.el-form-item__error{
  z-index: 999;
}
.el-message{
  min-width: initial !important;
}
</style>
