// 公共样式
export const commonStyle = {
    rotate: 0,
    opacity: 1,
}

export const commonAttr = {
    animations: [],
    events: {},
    groupStyle: {}, // 当一个组件成为 Group 的子组件时使用
    isLock: false, // 是否锁定组件
}

// 编辑器左侧组件列表
const list = [
    // 文本
    {
        component: 'v-imgCon',
        label: '图片',
        propValue: {
            imgUrl:require('@/assets/images/noImg.png'),
            href:'',
            widthImg:'90',
            heightImg:'161',
            backImg:require('@/assets/images/noImg.png'),
            borderR:1
        },
        icon: 'wenben',
        style: {},
        id:0,
    },
    
]

for (let i = 0, len = list.length; i < len; i++) {
    const item = list[i]
    item.style = { ...commonStyle, ...item.style }
    list[i] = { ...commonAttr, ...item }
}

export default list
