import axios from '../plugins/axios/request';
import store from '../store/index';


// 获取“预览模式”详细落地页表单组件数据
export const getFlowPageCacheById = (id = '') => {
    return new Promise((resolve, reject) => {
        axios({
            url: '/flowPage/getFlowPageCacheById',
            method: 'GET',
            params: {
                id: id
            },
        }).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    })
}

// 获取详细落地页表单组件数据
export const getFlowPageById = (id = '') => {
    return new Promise((resolve, reject) => {
        axios({
            url: '/user/getFlowPageById',
            // url: '/src/assets/data/testing-data.json',
            method: 'GET',
            params: {
                id: id
            },
        }).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    })
}

// 发送验证码
export const sendVerificationCode = (mobile = '') => {
    return new Promise((resolve, reject) => {
        axios({
            url: '/user/sendVerificationCode',
            method: 'GET',
            params: {
                mobile: mobile
            },
        }).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    })
}

// 校验手机验证码
export const submitMobile = (data = {}) => {
    return new Promise((resolve, reject) => {
        axios({
            url: '/user/submitMobile',
            method: 'POST',
            data: data,
            headers: {
                sign: data.sign,
            }
        }).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    })
}


// 提交表单
export const submitForm = (data) => {
    return new Promise((resolve, reject) => {
        axios({
            url: '/user/submitForm',
            method: 'POST',
            data: data,
        }).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    })
}

// 去支付
export const submitPay = (data) => {
    return new Promise((resolve, reject) => {
        
        axios({
            url: '/pay/submitPay',
            method: 'POST',
            data: data,
            headers: {
                token: store.state.userDatabaseForm.token? store.state.userDatabaseForm.token: null,
            }
        }).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error);
        })
    })
}

// 小程序唤醒链接
export function createSesameLink(params) {
  return axios({
    url: '/wechat/createSesameLink',
    method: 'get',
    params,
    headers: {
        token: store.state.userDatabaseForm.token? store.state.userDatabaseForm.token: null,
    }
  })
}

// 获取老师二维码
export function getWeChatCode(params) {
  return axios({
    url: '/wechat/getWeChatCode',
    method: 'get',
    params,
    headers: {
        token: store.state.userDatabaseForm.token? store.state.userDatabaseForm.token: null,
    }
  })
}