import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuex from 'vuex'
import store from './store/index'
import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui';
import axios from 'axios';
import {updateVueX} from './utils/index'

Vue.config.productionTip = false
Vue.prototype.$http = axios;
Vue.use(ElementUI);
Vue.use(Vuex)
updateVueX();//监听页面刷新，重置vueX数据。


import vButton from './custom-component/VButton'
import imagesList from './custom-component/ImagesListData'
import vText from './custom-component/VText'
import rectShape from './custom-component/RectShape'
import vOrder from './custom-component/VOrder'
import vPay from './custom-component/VPay'
import vPrivacy from './custom-component/VPrivacy'
import vPayall from './custom-component/VPayall'
import vRadio from './custom-component/vRadio'
import vRadioage from './custom-component/vRadio'

// 表单
import VInput from './custom-component/formLists/name'
import VText from './custom-component/formLists/Vtext'
import VFormtext from './custom-component/formLists/Vtext'
import VPhoneText from './custom-component/formLists/PhoneText'

// 图片上传组件
import vImagesLists from './custom-component/ImagesListData'
// 视频上传组件
import vIdeoListData from './custom-component/VideoListData'
// 图片上传子组件
import vImgCon from './custom-component/VImgCon'
// 视频上传子组件
import vVideo from './custom-component/Video'

import vButtonPay from './custom-component/VbuttonPay'


Vue.component('vButton',vButton)
Vue.component('imagesList',imagesList)
Vue.component('vText',vText)
Vue.component('vOrder',vOrder)
Vue.component('vPay',vPay)
Vue.component('vPrivacy',vPrivacy)
Vue.component('vPayall',vPayall)
Vue.component('vRadio',vRadio)
Vue.component('vRadioage',vRadioage)
Vue.component('rectShape',rectShape)
Vue.component('VInput',VInput)
Vue.component('VText',VText)
Vue.component('VPhoneText',VPhoneText)
Vue.component('vImagesLists',vImagesLists)
Vue.component('vImgCon',vImgCon)
Vue.component('vIdeoListData',vIdeoListData)
Vue.component('vVideo',vVideo)
Vue.component('vButtonPay',vButtonPay)
Vue.component('VFormtext',VFormtext)
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
