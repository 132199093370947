import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import flowPage from './flow-page/flow-page'
const store = {
    state: {
        canvasStyleData: { // 页面全局数据
            width: 1200,
            height: 740,
            scale: 100,
        },
        userDatabaseForm: {},//用户保存数据库的表单数据
        id: null,//落地页的ID
        mobileData: {},//手机验证码校验正确，返回的数据

        payType: 'ALI',//支付方式
        urlParams: {}, //巨量引擎参数
    },
    mutations: {
        // 设置用户保存数据库的表单数据
        setUserDatabaseForm(state, userDatabaseForm) {
            state.userDatabaseForm = userDatabaseForm;
        },
        // 设置落地页的ID
        setID(state, id) {
            state.id = id;
        },
        setMobileData(state, mobileData) {
            state.mobileData = mobileData;
        },
        // 设置支付方式
        setPayType(state, payType) {
            state.payType = payType;
        },
        // 设置巨量引擎参数
        setUrlParams(state, urlParams) {
            state.urlParams = urlParams;
        },
    },
    actions: {
    },
    modules: {
        flowPage,
    },
}

export default new Vuex.Store(store)