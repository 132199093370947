<template>
    <div class="imageConpon">
        <video :style="{width:propValue.videoWidth+'%',height:propValue.videoHeight+'px'}" controls>
            <source :src="videoUrl" type="video/mp4">
            <source :src="videoUrl" type="video/ogg">
        </video>
    </div>
</template>
<script>
export default {
    props: {
        propValue: {
            type: Object,
            require: true,
            default: () => {},
        },
    },
    element: {
        type: Object,
        default: () => {},
    },
    data() {
        return {
            videoUrl: '',
        }
    },
    watch: {
        propValue: {
            handler(newVal) {
                this.videoUrl = newVal.videoUrl
            },
             immediate: true
        },
    },
    methods: {

    },
}
</script>

<style scoped lang="scss">
.backimg {
    // width: 90%;
    height: 161px;
    background-image: url('../assets/images/noImg1.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 0 auto;
    margin-top: 10px;
}
</style>
