<!-- eslint-disable vue/no-v-html -->
<template>
    
    <div class="v-order">
        <span class="boxTitle">订单信息</span>
        <div class="box">
            <img src="@/assets/images/01.png" alt="" class="left">
            <div class="right">
                <span style="font-size:12px;color:#333333;margin-top:2px">{{propValue.title}}</span>
                <span style="font-size:10px;color:#999999;margin-top:2px">{{propValue.desc}}</span>
                <span style="font-size:10px;color:#ff494a;margin-top:2px">¥{{propValue.price}}</span>
            </div>
        </div>
    </div>
        
</template>

<script>
export default {
    props: {
        propValue: {
            type: Object,
            require: true,
            default: () => {},
        },
    },
    element: {
        type: Object,
        default: () => {},
    },
    data() {
        return {
            
        }
    },
    computed: {
    },
    methods: {
        
    },
}
</script>

<style lang="scss" scoped>
.v-order {
    width: 100%;
    height: 98px;
    // border: 1px solid #1e6bff;
    display: flex;
    flex-direction: column;
    .boxTitle {
        display: inline-block;
        margin-top: 8px;
        margin-left: 16px;
        font-size: 12px;
        font-weight: 400;
        color: #333333;
    }
    .box {
        display: flex;
        margin-top: 8px;
        margin-left: 16px;
        .left {
            display: block;
            width: 28%;
            height: 49px;
            margin-top: 2px;
        }
        .right {
            display: flex;
            flex-direction: column;
            margin-left: 8px;
        }
    }
}
</style>
